import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { BASE_S3 } from 'config/consts';
import { limitStringSize, stringAvatar } from 'helpers/utils';
import { deleteGroupingById, getGroupingsStationId } from './groupingsActions';
import * as XLSX from 'xlsx';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MAX_LENGTH = 30;

function Grouping(props) {
  useEffect(() => {
    props.getGroupingsStationId(props.auth.user.establishments[0].stations[0].id);
  }, []);
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const handleButtonClick = (data) => {
    let listaDeInformacao = data.grouping_user;
    let j = 0;
    if (!Array.isArray(listaDeInformacao) || listaDeInformacao.length === 0) {
      console.error('Lista de objetos vazia ou inválida.');
      return '';
    }
    // Obtém os cabeçalhos do CSV a partir das chaves selecionadas
    const cabe = ['Nome', 'CPF', 'Telefone', 'Email'];
    const linhas = [];

    for (let i = 0; i < listaDeInformacao.length; i++) {
      const user = listaDeInformacao[i];
      linhas[`${j}`] = [];
      linhas[j].push(user?.user?.name ?? '');
      linhas[j].push(user?.user?.cpf ?? '');
      linhas[j].push(user?.user?.tel ?? '');
      linhas[j].push(user?.user?.email ?? '');
      j++;
    }
    linhas[`${j}`] = [];
    const linhasCSV = linhas.map((linha) => linha.join(','));
    const csv = [cabe.join(','), ...linhasCSV].join('\n');
    try {
      // Parse da string CSV para um array de arrays
      const nomeAtividade = data.name;
      const csvData = csv.split('\n').map((row) => row.split(','));
      // Crie um workbook do Excel
      const workbook = XLSX.utils.book_new();
      // Crie uma planilha a partir dos dados CSV
      const worksheet = XLSX.utils.aoa_to_sheet(csvData);
      // Adicione a planilha ao workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      // Salve o workbook como um arquivo Excel
      XLSX.writeFile(workbook, `Participantes_${nomeAtividade}.xlsx`);
    } catch (error) {
      toastr.error('Erro', 'Não foi possível exportar o relatório.');
      console.error('Não foi possível gerar o relatório.', error);
    }
  };

  const classes = useStyles();

  const [confirmBox, setConfirmBox] = useState({ visible: false });
  const columns = [
    {
      title: '# Grupo',
      field: 'name',
      render: (rowData) => (
        <Grid container alignItems="center">
          <Grid item xs={3}>
            {rowData.logo_image ? (
              <Avatar
                alt="Logo"
                src={`${BASE_S3}/${rowData.logo_image}`}
                sx={{ width: 56, height: 56 }}
              />
            ) : (
              <Avatar {...stringAvatar(rowData.name)} />
            )}
          </Grid>
          <Grid item xs={9}>
            {rowData.name ? limitStringSize(rowData.name, MAX_LENGTH) : 'Sem título'}
          </Grid>
        </Grid>
      ),
    },
    {
      title: 'Descrição',
      field: 'description',
      render: (rowData) => rowData.description,
    },
    {
      title: 'Participantes',
      field: 'Participantes',
      render: (rowData) => (
        <div>
          <button onClick={() => handleButtonClick(rowData)}>
            {`${rowData.grouping_user.length}    `}
            <FontAwesomeIcon icon={faBook} style={{ color: 'green', fontSize: '17px' }} />
          </button>
        </div>
      ),
    },
    {
      title: '',
      sorting: false,
      field: 'edit',
      render: (rowData) => (
        <Box onClick={() => props.router.push(`/owner/groupings/editar/${rowData.id}`)}>
          <EditIcon style={{ color: '#043565;', cursor: 'pointer' }} />
        </Box>
      ),
    },
  ];

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui agrupamento de usuários na plataforma
                  </h2>
                  <p className="">
                    Crie agrupamento de usuários para segregar as atividades por grupos.
                  </p>
                  <PrimaryButton
                    onClick={() => props.router.push('/owner/groupings/criar')}
                    color="primary">
                    Novo Agrupamento
                  </PrimaryButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração" />
          </Grid>
        </Grid>
      </section>
    );
  }

  if (props.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (props.groupings.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ width: isMobile ? '89vw' : undefined }}>
          <Table
            hasToolbar
            toolbarSearch
            searchPlaceholder={'Pesquisar'}
            title="Agrupamentos"
            columns={columns}
            rows={props.groupings}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </Grid>
      </Grid>
      <AlertDialogSlide
        maxWidth="sm"
        title="Tem certeza?"
        onClose={() => setConfirmBox((value) => ({ ...value, visible: false }))}
        visible={confirmBox.visible}>
        <div>
          <PrimaryButton
            noMargin
            type="button"
            onClick={() =>
              props.deleteGroupingById(
                confirmBox.grouping_id,
                props.auth.user.establishments[0].stations[0].id,
                setConfirmBox
              )
            }
            disabled={props.groupings.buttonLoading}>
            Sim, quero deletar
          </PrimaryButton>

          <SecondaryButton onClick={() => setConfirmBox((value) => ({ ...value, visible: false }))}>
            Cancelar
          </SecondaryButton>
        </div>
      </AlertDialogSlide>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Fab
          variant="extended"
          style={{ background: '#fff' }}
          onClick={() => props.router.push('/owner/groupings/criar')}>
          <AddIcon className={classes.extendedIcon} />
          Criar agrupamento
        </Fab>
      </Box>
    </>
  );
}

Grouping.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deleteGroupingById: PropTypes.func,
  getGroupingsStationId: PropTypes.func,
  loading: PropTypes.any,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    groupings: state.groupings?.list,
    loading: state.groupings?.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGroupingsStationId,
      deleteGroupingById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Grouping);
