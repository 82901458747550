import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { parseISO, format } from 'date-fns';
import { initialize } from 'redux-form';
import types from './types';

const PRODUCT_ORDERS = `${BASE_API}/product-orders/?where[station_id]=`;
const PRODUCT_ACTION = `${BASE_API}/product-orders-rel`;
const PRODUCT_ORDERS_DETAIL = `${BASE_API}/product-orders`;
const VALIDATE_PRODUCT_ORDER = `${BASE_API}/validate-product-order`;
const CANCEL_PRODUCT_ORDER = `${BASE_API}/cancel-product-order`;
const PRODUCT_ORDER = `${BASE_API}/product-orders/?where[id]=`;
const DELIVERY_ORDERS = `${BASE_API}/product-delivery/?where[station_id]=`;
const DELIVERY_STATUSES = `${BASE_API}/delivery-statuses`;
const PRODUCT_ORDERS_GENERAL = `${BASE_API}/product-orders`;
const REFUND_URL = `${BASE_API}/gateway-refunds`;

export const getOrdersWithId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDERS}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const newResponseData = response.data.map((item) => ({
          ...item,
          createdAt: format(parseISO(item.createdAt), 'dd-MM-yyyy HH:mm'),
        }));
        dispatch([
          {
            type: types.PRODUCT_ORDERS_FETCHED,
            payload: newResponseData,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getProductAction = (id) => {
  return (dispatch) => {
    dispatch({ type: types.PRODUCT_ORDERS_LOAD, payload: true });
    axios
      .get(`${PRODUCT_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_ORDERS_LOAD,
            payload: response,
          },
        ]);
      });
  };
};

export const getProductOrderDetail = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDERS_DETAIL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_ORDERS_DETAIL,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.PRODUCT_ORDERS_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data?.messages) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.messages[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDeliverysWithId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_ORDERS}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_ORDERS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getOrderAndInitialize = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDER}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch(initialize('ProductOrderForm', response.data[0]));
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const validateProductOrder = (values, router = undefined, id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(`${VALIDATE_PRODUCT_ORDER}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        router.push('/owner/produtos-pedidos');
        window.location.reload();
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const cancelProductOrder = (id, partner_id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(
        `${CANCEL_PRODUCT_ORDER}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        }
      )
      .then((response) => {
        dispatch([
          getOrdersWithId(partner_id),
          {
            type: types.PRODUCT_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const updateObservationsAndDeliveryStatus = (id, { observations, delivery_status_id }) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(
        `${PRODUCT_ORDERS_GENERAL}/${id}`,
        { observations, delivery_status_id },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        }
      )
      .then((response) => {
        dispatch([
          getProductOrderDetail(id),
          {
            type: types.PRODUCT_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getOrder = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.PRODUCT_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDER}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_DETAIL_FETCHED,
            payload: response.data[0],
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDeliveryStatuses = () => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_STATUSES_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_STATUSES}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.DELIVERY_STATUSES_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: ` Errosss: ${e.response.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const refund = (refundData, cleanup) => {
  const { purchaseId: purchase_id, value, isPartial: is_partial } = refundData;
  return (dispatch) => {
    dispatch({
      type: types.PRODUCT_ORDERS_REFUND_LOAD,
      payload: true,
    });
    axios
      .put(
        `${REFUND_URL}/${purchase_id}`,
        {
          value,
          is_partial,
        },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_ORDERS_REFUND_LOAD,
            payload: false,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Estorno realizado com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (cleanup) {
          cleanup();
        }
      })
      .catch((e) => {
        try {
          dispatch([
            {
              type: types.PRODUCT_ORDERS_REFUND_LOAD,
              payload: false,
            },
            {
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data.message}`,
                variant: 'error',
                open: true,
              },
            },
          ]);
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
