import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Lottie from 'react-lottie';
import { withStyles } from '@material-ui/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import PrimaryButton from 'common/components/buttons/primaryButton';
import CardFooter from 'material-dashboard-components/Card/CardFooter.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import { getActionResults } from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { Typography, Box } from '@material-ui/core';
import moment from 'moment';
import { ColumnsBelowSearch, NumberGenericElement } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import UserAnswers from '../../userAnswers/userAnswers';
import { getAnswers } from 'modules/owner/pages/actions/userAnswers/userAnswersActions';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
const { format } = require('date-fns');

function dispatchMessage(message) {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: message,
        variant: 'error',
        open: true,
      },
    });
  };
}

class Results extends Component {
  constructor(props) {
    super(props);
    this.props.getActionResults(this.props.location.query.action);
    this.props.getAnswers(this.props.location.query.action);
    this.state = {
      individualAnswer: false,
      userIndividualAnswerId: null,
      value: undefined,
      drawWinners: [],
    };
  }

  onDialogClose = () => {
    if (this.props.location.query.from === 'inicio') {
      this.props.router.push('/owner/acoes/inicio');
    } else {
      this.props.router.push(
        `/owner/acoes/minhas-atividades/?to=${this.props.location.query.from}`
      );
    }
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Resultados"
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}>
        <section id="statement-buy-points" style={{ height: '100vh' }}>
          <GeneralGrid container spacing={3} alignItems="center">
            <GeneralGrid item xs={12} md={6}>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}>
                  <h2 className="signup-text">Ninguém participou dessa atividade até o momento</h2>
                  <p>Aqui você poderá distribuir os prêmios</p>
                </GeneralGrid>
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid item xs={12} md={6}>
              <img alt="" src={placeholder_illustration} className="img-fluid" />
            </GeneralGrid>
          </GeneralGrid>
        </section>
      </AlertDialogSlide>
    );
  }

  renderBackground = () => {
    return '#3f51b5';
  };

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  criarLista = (listaDeInformacao) => {
    let j = 1;
    let contador = 0;
    if (!Array.isArray(listaDeInformacao) || listaDeInformacao.length === 0) {
      console.error('Lista de objetos vazia ou inválida.');
      return '';
    }
    const linhas = [];
    linhas[`${0}`] = ['id'];
    for (let i = 0; i < listaDeInformacao.length; i++) {
      linhas[0].push(listaDeInformacao[i].question);
    }
    const nomeId = listaDeInformacao[0].Answers;

    const uniqueData = Array.from(new Map(nomeId.map((item) => [item.user_id, item])).values());

    for (let i = 0; i < uniqueData.length; i++) {
      linhas[`${j}`] = [];
      linhas[j].push(uniqueData[i].user_id);
      linhas[j].push(uniqueData[i].answer);
      j++;
    }
    for (let i = 1; i < listaDeInformacao.length; i++) {
      const data = listaDeInformacao[i].Answers;
      switch (listaDeInformacao[i].otherOption) {
        case null: {
          const uniqueData = Array.from(new Map(data.map((item) => [item.user_id, item])).values());
          for (let a = 1; a < linhas.length; a++) {
            const objA = linhas[a][0];
            const objB = uniqueData.find((item) => item.user_id === objA);
            if (objB) {
              linhas[a].push(objB.answer);
            }
          }
          break;
        }
        case true: {
          for (let a = 1; a < linhas.length; a++) {
            const uniqueData = Array.from(
              new Map(data.map((item) => [item.user_id, item])).values()
            );
            const objA = linhas[a][0];
            const objB = uniqueData.find((item) => item.user_id === objA);
            if (objB !== undefined) {
              if (objB.question_option !== null) {
                linhas[a].push(objB.question_option.label);
              } else {
                linhas[a].push(objB.answer);
              }
            } else {
              linhas[a].push('-');
            }
          }
          break;
        }
        default: {
          for (let a = 1; a < linhas.length; a++) {
            const objA = linhas[a][0];
            const objB = data.filter((item) => item.user_id === objA);
            const labels = objB.map((item) => item.question_option.label).join(' - ');
            if (objB) {
              linhas[a].push(labels);
            }
          }
        }
      }
    }
    linhas[`${j}`] = [];
    for (let a = 0; a < linhas.length; a++) {
      linhas[a].shift();
    }
    return linhas;
  };

  gerarExcel = (dados, titulo, actionName = '') => {
    try {
      // Parse da string CSV para um array de arrays
      const nomeAtividade =
        titulo[0]?.presential_course_subscriptions[0]?.presential_course?.action?.name ||
        actionName;
      const dataAtual = new Date();
      const dia = dataAtual.getDate();
      const mes = dataAtual.getMonth() + 1; // Adicione 1 porque os meses começam do zero
      const ano = dataAtual.getFullYear();
      // Crie um workbook do Excel
      const workbook = XLSX.utils.book_new();
      // Crie uma planilha a partir dos dados CSV
      const worksheet = XLSX.utils.json_to_sheet(dados, { skipHeader: true });
      // Adicione a planilha ao workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      // Salve o workbook como um arquivo Excel
      XLSX.writeFile(workbook, `relatorio_${nomeAtividade}_${dia}_${mes}_${ano}.xlsx`);
    } catch (error) {
      toastr.error('Erro', 'Não foi possível exportar o relatório.');
      console.error('Não foi possível gerar o relatório.', error);
    }
  };

  drawBetweenUserActions = () => {
    if (this.state.value) {
      const drawUsersNumers = this.state.value;
      const { usersReached } = this.props.activeActions.actionResults;
      const usersWhoParticipated = usersReached.filter((user) => user.user_actions.length > 0);
      if (drawUsersNumers > usersWhoParticipated.length) {
        return this.props.dispatchMessage(
          'o número de sorteados selecionado é maior que o número de participantes'
        );
      }
      const drawWinners = [];
      while (drawWinners.length < drawUsersNumers) {
        const number = this.getRandomInt(1, usersWhoParticipated.length);
        if (drawWinners.indexOf(usersWhoParticipated[number - 1].name) < 0) {
          drawWinners.push(usersWhoParticipated[number - 1].name);
        }
      }
      this.setState({ drawWinners });
    } else {
      this.props.dispatchMessage('Selecione o numero antes de tentar');
    }
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: require('../../../../../../assets/lottie/61153-trophy-congratulation.json'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const { classes } = this.props;
    const { actionResults, loadingActionResults, actionDetail } = this.props.activeActions;
    const {
      impressions,
      reach,
      participations,
      cashback,
      cashback_left,
      usersReached,
      multipleChoiceSelectOneQuestionsResults,
      totalAnswers,
      openQuestionsResults,
      multipleChoiceSelectManyQuestionsResults,
      multipleChoiceType4QuestionsResults,
    } = actionResults;
    const listaData = this.criarLista(this.props.userAnswers.list);
    if (loadingActionResults) {
      return (
        <AlertDialogSlide
          title="Resultados"
          visible={true}
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'md'}>
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </AlertDialogSlide>
      );
    }

    if (this.state.individualAnswer) {
      return (
        <AlertDialogSlide
          title={
            <span
              style={{
                color: '#2D95EF',
                cursor: 'pointer',
                fontWeight: '400',
                fontSize: 15,
              }}
              onClick={() =>
                this.setState({
                  individualAnswer: false,
                })
              }>
              Voltar
            </span>
          }
          visible={true}
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'md'}>
          <UserAnswers
            userId={this.state.userIndividualAnswerId}
            actionId={this.props.location.query.action}
            {...this.props}
          />
        </AlertDialogSlide>
      );
    }

    return (
      <AlertDialogSlide
        isFullScreen={isMobile}
        title={`${actionDetail.name} (${moment(actionDetail.start).format('DD/MM')} - ${
          moment(actionDetail.end).isAfter(new Date())
            ? 'Hoje'
            : moment(actionDetail.end).format('DD/MM')
        }) `}
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'md'}>
        <Typography variant="h6">Resultados gerais</Typography>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">remove_red_eye</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Impressões</p>
                <h3 className={classes.cardTitle}>{impressions}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de visualizações que sua atividade teve, independente se for do mesmo usuário ou de usuários diferentes."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">people_alt</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Alcance</p>
                <h3 className={classes.cardTitle}>{reach}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participantes que viram sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">call_made</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Participações</p>
                <h3 className={classes.cardTitle}>{participations.length}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participações efetivas na sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">bar_chart</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Conversão</p>
                <h3 className={classes.cardTitle}>
                  {parseFloat((participations.length / reach) * 100).toFixed(1)}%
                </h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a porcentagem de pessoas que participaram dessa atividade em relação a todas que foram alcançadas"
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">attach_money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Gasto atual</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é quantidade de dinheiro que já foi paga em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Cashback restante</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback_left).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é a quantidade de dinheiro que foi reservada para ação mas ainda não foi distribuida em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Typography variant="h6">Sorteio</Typography>
            <Typography variant="span">
              Essa atividade permite fazer um sorteio entre os que participaram dela
            </Typography>
          </GeneralGrid>

          <GeneralGrid item xs={4}>
            <InputLabelOutlined
              input={{ onChange: this.handleChange, value: this.state.value }}
              meta={{}}
              onChange={this.handleChange}
              value={this.state.value}
              type="number"
              label="Quantidade a ser sorteada"
              name="search"
              margin="normal"
            />
          </GeneralGrid>
          <GeneralGrid item xs={2}>
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <PrimaryButton
                type="button"
                color="primary"
                onClick={this.drawBetweenUserActions}
                style={{
                  margin: 0,
                  width: '100%',
                  textTransform: 'none',
                  fontSize: isMobile ? 12 : undefined,
                }}>
                Sortear
              </PrimaryButton>
            </Box>
          </GeneralGrid>
          <GeneralGrid item xs={12}>
            {this.state.drawWinners.length > 0 && (
              <>
                <Lottie options={defaultOptions} height={200} width={200} />

                <Typography style={{ textAlign: 'center' }} variant="h6">
                  Sorteados:{' '}
                </Typography>
                {this.state.drawWinners.map((winner, index) => (
                  <Typography key={index} style={{ textAlign: 'center' }} variant="h6">
                    {` ${winner}`}
                  </Typography>
                ))}
              </>
            )}
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            {!_.isEmpty(usersReached) && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <button
                    onClick={() => this.gerarExcel(listaData, usersReached, actionDetail.name)}
                    aria-label="Gerar Excel">
                    <FontAwesomeIcon
                      icon={faBook}
                      style={{ color: 'green', fontSize: '25px', marginLeft: '832px' }}
                    />
                  </button>
                </div>
              </div>
            )}
            <Table
              searchPlaceholder={'Procurar por nome de usuário'}
              hasToolbar={true}
              defaultSelected={''}
              title="Extrato"
              columns={[
                { field: 'name', title: 'Nome' },
                {
                  field: 'quantity',
                  title: 'Visualizações',
                  render: (rowData) => (
                    <NumberGenericElement category="visualizations" value={rowData.quantity} />
                  ),
                },
                {
                  title: 'Participação',
                  render: (rowData) =>
                    rowData.user_actions.length > 0 ? 'Visualizou e participou' : 'Visualizou',
                },
                {
                  title: 'Email',
                  field: 'email',
                },
                {
                  title: 'Search',
                  field: 'search',
                  render: (rowData) => (
                    <ColumnsBelowSearch
                      buttons={{
                        namedButtons: [
                          {
                            name:
                              rowData.user_actions.length > 0
                                ? 'Ver respostas'
                                : 'Ainda não participou',
                            onClick:
                              rowData.user_actions.length > 0
                                ? () => {
                                    this.setState({
                                      userIndividualAnswerId: rowData.id,
                                      individualAnswer: true,
                                    });
                                  }
                                : () => {},
                            disabled: !(rowData.user_actions.length > 0),
                          },
                        ],
                        iconButtons: [],
                      }}
                    />
                  ),
                },
              ]}
              rows={usersReached}
              toolbarSearch
              onChangeOption={() => {}}
              selectOptions={[]}
            />
          </GeneralGrid>
        </GeneralGrid>
        <Box mt={3}>
          <Typography variant="h6">Resultados da pesquisa</Typography>
        </Box>
        {participations.length === 0 ? (
          <Box mt={3}>
            <Typography variant="body1">Ninguém respondeu a pesquisa ainda</Typography>
          </Box>
        ) : (
          <>
            <GeneralGrid container spacing={3}>
              {multipleChoiceSelectOneQuestionsResults.map((item, index) => (
                <GeneralGrid item xs={12} key={index}>
                  <Box mt={3}>
                    <Typography variant="body1">
                      {item.question} (múltipla escolha podendo escolher apenas uma em lista
                      suspensa)
                    </Typography>
                  </Box>
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart data={item.options} layout="horizontal">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label" />
                      <YAxis allowDecimals={false} />
                      <RechartsTooltip formatter={(value, name) => [value, 'Total']} />
                      <Legend formatter={(value, entry) => ['Quantidade de respostas']} />
                      <ReferenceLine
                        y={totalAnswers}
                        stroke="red"
                        strokeDasharray="0 0"
                        alwaysShow
                        isFront={true}
                        label={{
                          value: 'Total de respostas',
                          angle: 0,
                          position: 'top',
                        }}
                      />
                      <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </GeneralGrid>
              ))}
            </GeneralGrid>
            <GeneralGrid container spacing={3}>
              {multipleChoiceType4QuestionsResults.map((item, index) => (
                <GeneralGrid item xs={12} key={index}>
                  <Box mt={3}>
                    <Typography variant="body1">
                      {item.question} (múltipla escolha podendo escolher apenas uma)
                    </Typography>
                  </Box>
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart data={item.options} layout="horizontal">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label" />
                      <YAxis allowDecimals={false} />
                      <RechartsTooltip formatter={(value, name) => [value, 'Total']} />
                      <Legend formatter={(value, entry) => ['Quantidade de respostas']} />
                      <ReferenceLine
                        y={totalAnswers}
                        stroke="red"
                        strokeDasharray="0 0"
                        alwaysShow
                        isFront={true}
                        label={{
                          value: 'Total de respostas',
                          angle: 0,
                          position: 'top',
                        }}
                      />
                      <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </GeneralGrid>
              ))}
            </GeneralGrid>
            <GeneralGrid container spacing={3}>
              {multipleChoiceSelectManyQuestionsResults.map((item, index) => (
                <GeneralGrid item xs={12} key={index}>
                  <Box mt={3}>
                    <Typography variant="body1">
                      {item.question} (múltipla escolha podendo escolher várias)
                    </Typography>
                  </Box>
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart data={item.options} layout="horizontal">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label" />
                      <YAxis allowDecimals={false} />
                      <RechartsTooltip formatter={(value, name) => [value, 'Total']} />
                      <Legend formatter={(value, entry) => ['Quantidade de respostas']} />
                      <ReferenceLine
                        y={totalAnswers}
                        stroke="red"
                        strokeDasharray="0 0"
                        alwaysShow
                        isFront={true}
                        label={{
                          value: 'Total de respostas',
                          angle: 0,
                          position: 'top',
                        }}
                      />
                      <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </GeneralGrid>
              ))}
            </GeneralGrid>
            {openQuestionsResults.length > 0 ? (
              <>
                <Box mt={3} mb={2}>
                  <Typography variant="body1">
                    {openQuestionsResults[0].question.question} (pergunta aberta)
                  </Typography>
                </Box>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Table6
                      title="Respostas abertas"
                      columns={[{ field: 'answer', title: 'Resposta' }]}
                      data={openQuestionsResults}
                      router={this.props.router}
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </>
            ) : null}
          </>
        )}
      </AlertDialogSlide>
    );
  }
}

Results.propTypes = {
  activeActions: PropTypes.shape({
    actionDetail: PropTypes.shape({
      end: PropTypes.any,
      name: PropTypes.any,
      start: PropTypes.any,
    }),
    actionResults: PropTypes.shape({
      cashback: PropTypes.any,
      cashback_left: PropTypes.any,
      impressions: PropTypes.any,
      multipleChoiceSelectManyQuestionsResults: PropTypes.shape({
        map: PropTypes.func,
      }),
      multipleChoiceSelectOneQuestionsResults: PropTypes.shape({
        map: PropTypes.func,
      }),
      openQuestionsResults: PropTypes.shape({
        length: PropTypes.number,
      }),
      participations: PropTypes.shape({
        length: PropTypes.number,
      }),
      reach: PropTypes.any,
      totalAnswers: PropTypes.any,
      usersReached: PropTypes.shape({
        filter: PropTypes.func,
      }),
    }),
    loadingActionResults: PropTypes.any,
  }),
  classes: PropTypes.shape({
    cardCategory: PropTypes.any,
    cardTitle: PropTypes.any,
    stats: PropTypes.any,
  }),
  dispatchMessage: PropTypes.func,
  getActionResults: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      action: PropTypes.any,
      from: PropTypes.string,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    userAnswers: state.userAnswers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionResults,
      dispatchMessage,
      getAnswers,
    },
    dispatch
  );
};

const StyledResults = withStyles(styles)(Results);

export default connect(mapStateToProps, mapDispatchToProps)(StyledResults);
