import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import ConfirmationCard from 'common/components/cards/confirmationCard/confirmationCard';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import MaterialCard from 'common/components/cards/materialCard';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import 'primereact/resources/themes/nova/theme.css';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.css';
import MaterialGrid from '@material-ui/core/Grid';
import { ActionNameElement } from 'common/components/table/tableComponents';
import 'common/components/table/tableGeneral.css';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Engagement from 'assets/titlesMobile/engagement.svg';
import { getTemplates } from '../actions/actionsFile';
import { Delete } from '@material-ui/icons';
import { removeAccess, getLazyUser, getTotalNumberUser } from './contactsActions';

function GeneralActions(props) {
  const { auth } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState({});
  const [item, setItem] = useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [finishDelete, setFinishDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '', matchMode: 'contains' },
    },
  });

  useEffect(() => {
    props.getTemplates(auth.user.establishments[0].id);
    setSelectedOption('online');
  }, [refresh]);

  useEffect(() => {
    if (selectedOption === 'online') {
      setTotalRecords(props.contacts?.userTotal?.total ?? 7000);
      setData(props.contacts.list.content);
    }
    setLoading(false);
  }, [props.contacts.list.content, refresh]);

  useEffect(() => {
    lazyState.first = 0;
    lazyState.page = 0;
    lazyState.rows = 10;
    setLoading(true);
    props.getTotalNumberUser(auth.user.establishments[0].stations[0].id, lazyState);
    props.getLazyUser(auth.user.establishments[0].stations[0].id, lazyState);
  }, [refresh]);

  useEffect(() => {
    setLoading(true);
    props.getLazyUser(auth.user.establishments[0].stations[0].id, lazyState);
    props.getTotalNumberUser(auth.user.establishments[0].stations[0].id, lazyState);
  }, [lazyState, refresh]);

  const useStyles = makeStyles({
    root: {
      display: 'contents',
      '& .p-datatable-tbody': {
        display: 'none',
      },
      '& .p-column-header-content': {
        display: 'none',
      },
    },
  });

  const classes = useStyles();

  function handleDelete(id, resolve, reject) {
    props.removeAccess(
      id,
      setFinishDelete,
      props.auth.user.establishments[0].stations[0].id,
      setOpenConfirmation
    );
    props.getLazyUser(auth.user.establishments[0].stations[0].id, lazyState);
    props.getTotalNumberUser(auth.user.establishments[0].stations[0].id, lazyState);
  }

  const renderEmptyCard = () => {
    return (
      <MaterialGrid item xs={12} md={3} style={{ marginBottom: 10 }}>
        <MaterialCard
          horizontalCard
          router={props.router}
          cardEmpty
          onClick={() => {
            props.router.push('/owner/acoes');
          }}
          name={'Galeria de Atividades'}
          image={``}
          type={''}
        />
      </MaterialGrid>
    );
  };

  const itemTemplate = (option) => {
    return (
      <div
        style={{
          paddingRight: '10px',
          paddingLeft: '10px',
        }}>
        {option.label}
      </div>
    );
  };

  const emailBodyTemplate = (rowData) => {
    return <span>{rowData['email']}</span>;
  };
  const telBodyTemplate = (rowData) => {
    return <span>{rowData['tel']}</span>;
  };
  const cpfBodyTemplate = (rowData) => {
    return <span>{rowData['cpf']}</span>;
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <ActionNameElement
        className="p-column-filter"
        singleColum={false}
        actionName={rowData['name']}
      />
    );
  };

  const botaoAcaoDeletar = (rowData) => {
    return (
      <Tooltip title="Deletar">
        <IconButton
          aria-label="Deletar"
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            setOpenConfirmation(true);
            setItem(rowData.id);
          }}>
          <Delete />
        </IconButton>
      </Tooltip>
    );
  };

  const acoesBodyTemplate = (rowData) => {
    if (selectedOption === 'online') {
      return <div>{botaoAcaoDeletar(rowData)}</div>;
    }
  };

  const onFilter = (event) => {
    event['first'] = 0;
    setlazyState(event);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setlazyState((prevState) => ({
      ...prevState,
      first: newPage * rowsPerPage,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setlazyState((prevState) => ({
      ...prevState,
      first: 0,
      rows: newRowsPerPage,
    }));
  };

  const onPage = (event) => {
    setlazyState(event);
  };

  const isScheduled = (activity) => {
    if (activity.active && !activity.is_draft) {
      if (moment(new Date()).isBefore(activity.start)) return true;
    }
    return false;
  };
  return (
    <MaterialGrid
      container
      justifyContent="center"
      xs={12}
      style={{
        maxWidth: '86vw',
        paddingBottom: '4rem',
      }}>
      <GenericConfirmationCard
        cancelClose={() => setOpenConfirmation(false)}
        open={openConfirmation}
        // state={props.contacts}
        loading={props.contacts.loading}
        general={props.general}
        title="Deletar Cadastro"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja remover esse cadastro?"
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid
        container
        justifyContent="center"
        spacing={2}
        style={{
          display: 'flex',
          marginBottom: 15,
          marginTop: 15,
        }}>
        {isMobile ? (
          <div className="d-flex justify-content-start align-items-center container-fluid">
            <div style={{ width: 23, height: 23 }}>
              <img src={Engagement} alt="engagement" style={{ width: 23, height: 'auto' }} />
            </div>
            <h4
              style={{
                fontSize: 20,
                fontWeight: 400,
                margin: 0,
                marginLeft: 15,
              }}>
              Engajamento
            </h4>
          </div>
        ) : null}
      </GeneralGrid>
      <MaterialGrid
        container
        style={{
          marginTop: isMobile ? 0 : 15,
          marginBottom: isMobile ? 0 : 15,
        }}>
        <div className={classes.root}>
          <DataTable
            value={data}
            lazy
            totalRecords={totalRecords}
            rows={lazyState.rows}
            rowsPerPageOptions={[10, 25, 50]}
            dataKey="id"
            scrollable
            loading={loading}
            first={lazyState.first}
            onFilter={onFilter}
            onPage={onPage}
            resizableColumns
            filterDisplay="row"
            filters={lazyState.filters}
            style={{ width: '100%' }}>
            <Column
              field="name"
              header="Nome"
              sortable
              filter
              filterPlaceholder="Pesquisar"
              style={{ minWidth: '14rem', minHeight: '45rem' }}></Column>
          </DataTable>
        </div>
      </MaterialGrid>
      <>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Excluir</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{nameBodyTemplate(row)}</TableCell>
                  <TableCell>{cpfBodyTemplate(row)}</TableCell>
                  <TableCell>{emailBodyTemplate(row)}</TableCell>
                  <TableCell>{telBodyTemplate(row)}</TableCell>
                  <TableCell>{acoesBodyTemplate(row)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </TableContainer>
      </>
      <ConfirmationCard
        onClose={() => setOpen(false)}
        open={open}
        item={template.id}
        station_id={props.auth.user.establishments[0].stations[0].id}
        partner_id={null}
        sponsored={null}
        router={props.router}
      />
    </MaterialGrid>
  );
}

GeneralActions.propTypes = {
  actions: PropTypes.shape({
    templatesList: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
    templatesLoading: PropTypes.any,
  }),
  getLazyUser: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_time: PropTypes.any,
    }),
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getLazyStationActiveActions: PropTypes.func,
  getTemplates: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      to: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    userTotal: state.contacts.userTotal,
    auth: state.auth,
    general: state.general,
    contacts: state.contacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLazyUser,
      getTotalNumberUser,
      removeAccess,
      getTemplates,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralActions);
